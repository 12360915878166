.App {
  text-align: center
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* font-size: calc(10px + 2vmin); */
  justify-content: center;
  min-height: 100vh
}

.App-header-row {
  background-color: rgb(185, 113, 19);
  display: flex;
  flex-direction: row;
  height: 20vmin;
  width: 100%;
}

.top-row {
  background-color: #282c34;
  flex: 1;
  width: 100%;
  height: 100%;  
}

.bottom-row {
  background-color: #0d4150;
  flex: 1; 
  width: 100%;
  height: 100%;
}

.wave {
  background-color: #282c34;
  flex: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
